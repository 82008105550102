var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      staticClass: "my-modal",
      attrs: {
        "class-name": "dark",
        title: "设置",
        width: "30%",
        height: "80%",
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        position: { top: "10%", left: "40%" },
      },
      on: {
        close: function ($event) {
          _vm.open = false
        },
      },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "a-tabs",
        {
          staticStyle: { padding: "0 10px", height: "auto" },
          model: {
            value: _vm.activeKey,
            callback: function ($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey",
          },
        },
        [
          _c("a-tab-pane", { key: "0", attrs: { tab: "整体设置" } }, [
            _c("div", { staticStyle: { "margin-top": "20px" } }, [
              _c(
                "div",
                { staticClass: "my-switch" },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": "开",
                      "un-checked-children": "关",
                    },
                    model: {
                      value: _vm.switchConfig.autoAdjustMapCenterPoint,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.switchConfig,
                          "autoAdjustMapCenterPoint",
                          $$v
                        )
                      },
                      expression: "switchConfig.autoAdjustMapCenterPoint",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "系统自动根据所选车辆及人员调整地图中心点及缩放级别"
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "人员设置" } },
            [
              _c("object-tree", {
                ref: "vehicleTree",
                staticStyle: { height: "35vh" },
                attrs: {
                  "tree-data": _vm.cleanerTreeData,
                  "place-holder": "输入工牌编号或姓名进行搜索",
                  "object-title": "车辆",
                  "object-icon": "carIcon",
                  checkedList: _vm.employeeDeviceIds,
                },
                on: { check: _vm.handleCheckCleaner },
              }),
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.employeeRealLocation,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "employeeRealLocation",
                            $$v
                          )
                        },
                        expression: "switchConfig.employeeRealLocation",
                      },
                    }),
                    _c("span", [_vm._v("展示人员实时位置")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.employeeSelectedNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "employeeSelectedNumber",
                            $$v
                          )
                        },
                        expression: "switchConfig.employeeSelectedNumber",
                      },
                    }),
                    _c("span", [_vm._v("展示选中的人数")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.employeeOnline,
                        callback: function ($$v) {
                          _vm.$set(_vm.switchConfig, "employeeOnline", $$v)
                        },
                        expression: "switchConfig.employeeOnline",
                      },
                    }),
                    _c("span", [_vm._v("展示在线的人数")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-input-number", {
                      staticStyle: {
                        width: "60px",
                        "margin-top": "5px",
                        "margin-right": "10px",
                      },
                      attrs: {
                        id: "inputNumber",
                        precision: 0,
                        min: 1,
                        max: 60,
                      },
                      model: {
                        value: _vm.switchConfig.employeeOnlineTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.switchConfig, "employeeOnlineTime", $$v)
                        },
                        expression: "switchConfig.employeeOnlineTime",
                      },
                    }),
                    _c("span", [
                      _vm._v("分钟内无点位上传，则判定为离线（1~60）"),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "传统车辆设置" } },
            [
              _c("object-tree", {
                ref: "vehicleTree",
                staticStyle: { height: "35vh" },
                attrs: {
                  "tree-data": _vm.traditionVehicleTreeData,
                  "place-holder": "输入车辆编号进行搜索",
                  "object-title": "车辆",
                  "object-icon": "carIcon",
                  checkedList: _vm.traditionVehicleDeviceIds,
                },
                on: { check: _vm.handleCheckTraditionalVehicle },
              }),
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.traditionVehicleRealLocation,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "traditionVehicleRealLocation",
                            $$v
                          )
                        },
                        expression: "switchConfig.traditionVehicleRealLocation",
                      },
                    }),
                    _c("span", [_vm._v("展示车辆实时位置")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.traditionVehicleSelectedNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "traditionVehicleSelectedNumber",
                            $$v
                          )
                        },
                        expression:
                          "switchConfig.traditionVehicleSelectedNumber",
                      },
                    }),
                    _c("span", [_vm._v("展示选中的车辆数量")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.traditionVehicleOnline,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "traditionVehicleOnline",
                            $$v
                          )
                        },
                        expression: "switchConfig.traditionVehicleOnline",
                      },
                    }),
                    _c("span", [_vm._v("展示在线的车辆数量")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.traditionTodayForWorkOdom,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "traditionTodayForWorkOdom",
                            $$v
                          )
                        },
                        expression: "switchConfig.traditionTodayForWorkOdom",
                      },
                    }),
                    _c("span", [_vm._v("展示今日出勤里程")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.traditionYesterdayForWorkOdom,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "traditionYesterdayForWorkOdom",
                            $$v
                          )
                        },
                        expression:
                          "switchConfig.traditionYesterdayForWorkOdom",
                      },
                    }),
                    _c("span", [_vm._v("展示昨日出勤里程")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "智驾车辆设置" } },
            [
              _c("object-tree", {
                ref: "vehicleTree",
                staticStyle: { height: "35vh" },
                attrs: {
                  "tree-data": _vm.autoVehicleTreeData,
                  "place-holder": "输入车辆编号进行搜索",
                  "object-title": "车辆",
                  "object-icon": "carIcon",
                  checkedList: _vm.autoVehicleIds,
                },
                on: { check: _vm.handleCheckAutoVehicle },
              }),
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.autoVehicleRealLocation,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "autoVehicleRealLocation",
                            $$v
                          )
                        },
                        expression: "switchConfig.autoVehicleRealLocation",
                      },
                    }),
                    _c("span", [_vm._v("展示车辆实时位置")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.autoVehicleSelectedNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "autoVehicleSelectedNumber",
                            $$v
                          )
                        },
                        expression: "switchConfig.autoVehicleSelectedNumber",
                      },
                    }),
                    _c("span", [_vm._v("展示选中的车辆数量")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.autoVehicleOnline,
                        callback: function ($$v) {
                          _vm.$set(_vm.switchConfig, "autoVehicleOnline", $$v)
                        },
                        expression: "switchConfig.autoVehicleOnline",
                      },
                    }),
                    _c("span", [_vm._v("展示在线的车辆数量")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.autoTodayForWorkOdom,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "autoTodayForWorkOdom",
                            $$v
                          )
                        },
                        expression: "switchConfig.autoTodayForWorkOdom",
                      },
                    }),
                    _c("span", [_vm._v("展示今日出勤里程")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.switchConfig.autoYesterdayForWorkOdom,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.switchConfig,
                            "autoYesterdayForWorkOdom",
                            $$v
                          )
                        },
                        expression: "switchConfig.autoYesterdayForWorkOdom",
                      },
                    }),
                    _c("span", [_vm._v("展示昨日出勤里程")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "bottom-control",
          staticStyle: { "margin-top": "30px", height: "auto" },
        },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v(" 取消 "),
              ]),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
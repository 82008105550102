<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <vxe-modal
    class-name="dark"
    v-model="open"
    title="设置"
    width="30%"
    height="80%"
    class="my-modal"
    @close="open = false"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :position="{ top: '10%', left: '40%' }"
  >
    <a-tabs v-model="activeKey" style="padding: 0 10px; height: auto">
      <a-tab-pane key="0" tab="整体设置">
        <div style="margin-top: 20px">
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.autoAdjustMapCenterPoint" />
            <span>系统自动根据所选车辆及人员调整地图中心点及缩放级别</span>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="1" tab="人员设置">
        <object-tree
          style="height: 35vh"
          ref="vehicleTree"
          :tree-data="cleanerTreeData"
          place-holder="输入工牌编号或姓名进行搜索"
          object-title="车辆"
          object-icon="carIcon"
          @check="handleCheckCleaner"
          :checkedList="employeeDeviceIds"
        />
        <div style="margin-top: 20px">
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.employeeRealLocation" />
            <span>展示人员实时位置</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.employeeSelectedNumber" />
            <span>展示选中的人数</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.employeeOnline" />
            <span>展示在线的人数</span>
          </div>
          <div>
            <a-input-number id="inputNumber" v-model="switchConfig.employeeOnlineTime" :precision="0" :min="1" :max="60" style="width: 60px; margin-top: 5px; margin-right: 10px" />
            <span>分钟内无点位上传，则判定为离线（1~60）</span>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="传统车辆设置">
        <object-tree
          style="height: 35vh"
          ref="vehicleTree"
          :tree-data="traditionVehicleTreeData"
          place-holder="输入车辆编号进行搜索"
          object-title="车辆"
          object-icon="carIcon"
          @check="handleCheckTraditionalVehicle"
          :checkedList="traditionVehicleDeviceIds"
        />

        <div style="margin-top: 20px">
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.traditionVehicleRealLocation" />
            <span>展示车辆实时位置</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.traditionVehicleSelectedNumber" />
            <span>展示选中的车辆数量</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.traditionVehicleOnline" />
            <span>展示在线的车辆数量</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.traditionTodayForWorkOdom" />
            <span>展示今日出勤里程</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.traditionYesterdayForWorkOdom" />
            <span>展示昨日出勤里程</span>
          </div>
        </div>
      </a-tab-pane>

      <a-tab-pane key="3" tab="智驾车辆设置">
        <object-tree
          style="height: 35vh"
          ref="vehicleTree"
          :tree-data="autoVehicleTreeData"
          place-holder="输入车辆编号进行搜索"
          object-title="车辆"
          object-icon="carIcon"
          @check="handleCheckAutoVehicle"
          :checkedList="autoVehicleIds"
        />

        <div style="margin-top: 20px">
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.autoVehicleRealLocation" />
            <span>展示车辆实时位置</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.autoVehicleSelectedNumber" />
            <span>展示选中的车辆数量</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.autoVehicleOnline" />
            <span>展示在线的车辆数量</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.autoTodayForWorkOdom" />
            <span>展示今日出勤里程</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="switchConfig.autoYesterdayForWorkOdom" />
            <span>展示昨日出勤里程</span>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div class="bottom-control" style="margin-top: 30px; height: auto">
      <a-space>
        <a-button @click="handleCancel"> 取消 </a-button>
        <a-button type="primary" @click="handleSave"> 保存 </a-button>
      </a-space>
    </div>
  </vxe-modal>
</template>

<script>
import { Tabs } from 'ant-design-vue'
import ObjectTree from '@/views/monitor/monitorComponents/objectTree'
import { deviceTree } from '@/api/iot/device'
import { getVehicleTree } from '@/api/iot/vehicle'
import { DashboardConfigUpdate } from '@/api/system/config'
export default {
  components: { ATabs: Tabs, ATabPane: Tabs.TabPane, ObjectTree },
  props: {
    cfg: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      open: false,
      activeKey: '0',
      traditionVehicleTreeData: [],
      autoVehicleTreeData: [],
      cleanerTreeData: [],
      switchConfig: {
        // 保洁人员
        employeeRealLocation: true,
        employeeSelectedNumber: true,
        employeeOnline: true,
        // 传统车辆
        traditionVehicleRealLocation: true,
        traditionVehicleSelectedNumber: true,
        traditionVehicleOnline: true,
        traditionTodayForWorkOdom: true,
        traditionYesterdayForWorkOdom: true,
        // 智驾车辆
        autoVehicleRealLocation: true,
        autoVehicleSelectedNumber: true,
        autoVehicleOnline: true,
        autoTodayForWorkOdom: true,
        autoYesterdayForWorkOdom: true,
        // 系统自动根据所选车辆及人员调整地图中心点及缩放级别
        autoAdjustMapCenterPoint: true,
        // 分钟内无点位上传，则判定为离线（1~60）
        employeeOnlineTime: 1
      },
      employeeDeviceIds: [],
      traditionVehicleDeviceIds: [],
      autoVehicleIds: []
    }
  },
  watch: {
    cfg(newVal, oldVal) {
      if (newVal) {
        Object.keys(this.switchConfig).forEach((p) => {
          if (p === 'employeeOnlineTime' && newVal[p]) {
            this.switchConfig[p] = newVal[p]
          } else {
            this.switchConfig[p] = newVal[p] === 'Y'
          }
        })
        this.employeeDeviceIds = newVal.employeeDeviceIds
        this.traditionVehicleDeviceIds = newVal.traditionVehicleDeviceIds
        this.autoVehicleIds = newVal.autoVehicleIds
        // console.log('switchConfig', this.switchConfig)
      }
    }
  },
  mounted() {},
  methods: {
    handleSetup() {
      this.getTreeData(2)
      this.getTreeData(1)
      this.initVehicleTree()
      this.open = true
    },
    initVehicleTree() {
      getVehicleTree().then((res) => {
        if (res.data) {
          this.autoVehicleTreeData = res.data
        }
      })
    },
    getTreeData(deviceType) {
      const formData = new FormData()
      formData.append('deviceType', deviceType)
      deviceTree(formData).then((response) => {
        // console.log('getTreeData')
        if (deviceType === 1) {
          this.traditionVehicleTreeData = response.data
          // 设置上次选择的ID列表
          // this.traditionVehicleDeviceIds = ['1014676']
        } else if (deviceType === 2) {
          this.cleanerTreeData = response.data
          // 设置上次选择的ID列表
          // this.employeeDeviceIds = ['3302383']
        }
      })
    },

    // 打勾选择设备，当前打勾的设备列表，当前选中的设备Id
    handleCheckTraditionalVehicle(checkedList, selectedId, isSameList) {
      // console.log('handleCheckTraditionalVehicle', checkedList)
      this.traditionVehicleDeviceIds = checkedList
    },

    handleCheckAutoVehicle(checkedList, selectedId, isSameList) {
      // console.log('handleCheckAutoVehicle', checkedList)
      this.autoVehicleIds = checkedList
    },

    handleCheckCleaner(checkedList, selectedId, isSameList) {
      // console.log('handleCheckCleaner', checkedList)
      this.employeeDeviceIds = checkedList
    },
    loadLocalConfig() {
      const cleanerDashboardConfig = localStorage.getItem('cleanerDashboardConfig')
      if (cleanerDashboardConfig) {
        const tmpConfig = JSON.parse(cleanerDashboardConfig)
        this.cleanerSwitch = { ...tmpConfig.cleanerSwitch }
        this.traVehicleSwitch = { ...tmpConfig.traVehicleSwitch }
      }
    },
    handleSave() {
      // 配置保存在本地
      const params = {}
      Object.keys(this.switchConfig).forEach((p) => {
        if (p === 'employeeOnlineTime') {
          params[p] = this.switchConfig[p]
        } else {
          params[p] = this.switchConfig[p] ? 'Y' : 'N'
        }
      })
      params.employeeDeviceIds = this.employeeDeviceIds
      params.traditionVehicleDeviceIds = this.traditionVehicleDeviceIds
      params.autoVehicleIds = this.autoVehicleIds
      // console.log('更新智驾节点', this.autoVehicleIds)
      DashboardConfigUpdate(params).then((res) => {
        this.$emit('callback')
        this.open = false
      })
    },
    handleCancel() {
      this.open = false
    }
  }
}
</script>
<style lang="less" scoped>
.my-switch {
  padding: 5px 0;
  > :last-child {
    margin-left: 10px;
  }
}
.bottom-control {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
}
</style>
